import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService, RouterService } from '@shared/services';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  readonly #routerService = inject(RouterService);
  readonly #auth = inject(AuthService);

  canActivate() {
    if (!!this.#auth.token && !!this.#auth.user) {
      return true;
    }
    this.#routerService.navigateToLogin();
    return false;
  }
}

// TODO(gustavoauma): Migrate to authGuard.
export const authGuard: CanActivateFn = () => {
  const token = localStorage.getItem('token');
  const router = inject(RouterService);
  if (token) {
    return true;
  } else {
    router.navigateToLogin();
    return false;
  }
};
